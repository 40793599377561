import React from 'react'
import Layout from '../components/layout'

const terms = () => {
  return (
    <Layout>
      <div className='relative py-16 bg-white overflow-hidden'>
        <div className='relative px-4 sm:px-6 lg:px-8'>
          <div className='text-lg max-w-prose mx-auto'>
            <h1 className='block  text-center text-customBlue font-semibold tracking-wide uppercase'>
              Terms and Conditions
            </h1>
            <span className='block  text-center'>
              Last Updated  December 22, 2023
            </span>
            <br />
            <p>
              These terms of service (these "Terms") apply to the services and
              products (collectively, the "Service") provided by TrustLoKR LLC
              ("TrustLoKR," "our," or "we") via TrustLoKR's site and mobile
              application.
            </p>
            <p></p>
            <p>
              These Terms are effective as of the date you first access the
              Service.
            </p>
            <p></p>
            <p>
              PLEASE READ THESE TERMS CAREFULLY, INCLUDING THE MANDATORY
              ARBITRATION AGREEMENT IN SECTION 12 OF THESE TERMS, WHICH REQUIRES
              THAT DISPUTES RELATED TO THESE TERMS AND THE SERVICE, BE RESOLVED
              BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL BASIS, NOT A
              CLASS-WIDE OR CONSOLIDATED BASIS. IF YOU DO NOT WISH TO BE SUBJECT
              TO ARBITRATION, YOU MAY OPT OUT OF THE ARBITRATION AGREEMENT BY
              FOLLOWING THE INSTRUCTIONS PROVIDED IN SECTION 12 OF THESE TERMS.
            </p>
            <p></p>
            <p>
              We reserve the right to change these Terms at any time in our sole
              discretion by providing notice that these Terms have been changed.
              We may provide notice by sending an email, posting a notice via
              the Service, posting the revised Terms on our site or mobile
              application and revising the date at the top of these Terms, or
              another form of notice. Any changes or modifications will be
              effective immediately and your continued use of the Service
              following the notice will constitute your acceptance of the
              changes.
            </p>
            <p></p>
            <h2>1. Privacy</h2>
            <p>
              For information about how we collect, use, share or otherwise
              process information about you, please see our Privacy Policy.
            </p>
            <p></p>
            <h2>2. Service</h2>
            <p>
              TrustLoKR allows users to securely record and share important
              personal information. TrustLoKR grants to you a non-exclusive,
              non-transferable, non-sublicensable, revocable, and limited
              license to access and use the Service subject to the terms and
              conditions set forth in these Terms.
            </p>
            <p></p>
            <h2>3. Registration</h2>
            <p>
              In order to access the Service, you must register with TrustLoKR
              by completing the registration forms provided via the Site. You
              agree to (a) provide accurate, current, and complete information
              as may be prompted by the registration forms via the Site
              ("Registration Data"), (b) maintain the security of your TrustLoKR
              account password, (c) maintain and promptly update the
              Registration Data, and any other information you provide to
              TrustLoKR, to keep it accurate, current, and complete and (d)
              accept all risks of unauthorized access to the Registration Data
              and any other information you provide to TrustLoKR.
            </p>
            <p></p>
            <p>
              You are responsible for safeguarding the passwords you use to
              access the Service and agree to be fully responsible for
              activities or transactions that relate to your TrustLoKR account
              or password. You must notify TrustLoKR immediately if you learn of
              an unauthorized use of your TrustLoKR account or password.
            </p>
            <p></p>
            <h2>4. Subscription Plans and Cancellation Policy</h2>
            <p>
              The Service is accessible pursuant to one of the TrustLoKR
              &lsquo;s subscription plans. Each plan includes a description of
              the Service, the term such Service is accessible, the applicable
              fees ("Subscription Fees"), and additional terms. The terms and
              conditions of the subscription plan you select are incorporated
              into these Terms by reference.{' '}
            </p>
            <p></p>
            <p>
              Unless otherwise suspended or terminated, you have the right to
              access the Service for the term set forth in the TrustLoKR
              subscription plan you select and such term will automatically
              renew for successive periods equivalent to the length of the
              initial term you selected (collectively, the "Subscription Term").
              You will be charged the Subscription Fees set forth in the
              TrustLoKR plan you select in accordance with the applicable
              payment terms. You may change the type of TrustLoKR subscription
              plan selected or terminate the TrustLoKR subscription plan
              according to the terms and conditions of such subscription plan.
              Except as set forth below, or unless we state otherwise, all
              payments (a) must be made in U.S. dollars, (b) must be made by
              payment card via TrustLoKR or our authorized payment processor,
              and (c) are non-refundable.
            </p>
            <p></p>
            <p>
              If your payment method fails, you withdraw your authorization
              above, or your payment is past due, we may suspend your use of the
              Service. We also may collect fees owed by charging other payment
              methods on file with us or retain collection agencies and legal
              counsel. TrustLoKR, or the collection agencies we retain, may also
              report information about your account to credit bureaus, and as a
              result, late payments, missed payments, or other defaults on your
              account may be reflected in your credit report.
            </p>
            <p></p>
            <p>
              WHEN YOU CREATE AN ACCOUNT FOR THE SERVICE, YOU EXPRESSLY
              ACKNOWLEDGE AND AGREE THAT:
            </p>
            <p></p>
            <p>
              TRUSTLOKR (OR OUR THIRD-PARTY PAYMENT PROCESSOR) IS AUTHORIZED TO
              CHARGE THE PAYMENT CARD PROVIDED BY YOU IN ACCORDANCE WITH THESE
              TERMS (IN ADDITION TO ANY APPLICABLE TAXES AND OTHER CHARGES) FOR
              THE DURATION OF THE SUBSCRIPTION TERM. PLEASE REMEMBER THAT,
              UNLESS CANCELLED OR UNLESS WE SUSPEND OR STOP PROVIDING ACCESS TO
              THE SERVICE, YOUR SUBSCRIPTION TO THE SERVICE WILL AUTOMATICALLY
              RENEW, AND WE WILL THEREFORE AUTOMATICALLY CHARGE THE SUBSCRIPTION
              FEES TO YOU FOR THE ENTIRE SUBSCRIPTION TERM.
            </p>
            <p></p>
            <p>
              YOUR SUBSCRIPTION TO THE SERVICE IS CONTINUOUS UNTIL YOU CANCEL IT
              OR WE SUSPEND OR STOP PROVIDING ACCESS TO THE SERVICE IN
              ACCORDANCE WITH THESE TERMS.
            </p>
            <p></p>
            <p>
              You may cancel your subscription to the Service at any time by
              emailing
              <a href='mailto:contact@trustlokr.com'>contact@trustlokr.com</a>.
              While you may cancel your subscription to the Service at any time,
              you acknowledge and agree that any and all fees (including, but
              not limited to, the Subscription Fees) you have paid or are
              payable for the Service are non-refundable. This means that,
              although you may cancel your subscription to the Service before
              the end of the applicable Subscription Term, you remain
              responsible for the fees for the entire Subscription Term,
              regardless of the date of cancelation.
            </p>
            <p></p>
            <h2>5. Your Content and Limited Use Rights</h2>
            <p>
              All content including messages, text, photos, and other materials
              and information (collectively, "User Content") you upload to the
              Service or otherwise use in connection with the Service is
              retained by you, except that you grant us a non-exclusive,
              royalty-free, worldwide, fully paid, and sublicensable license to
              use, reproduce, modify, adapt, publish, translate, create
              derivative works from your User Content solely in order to provide
              the Service.
            </p>
            <p></p>
            <p>
              You may not create, post, store or share any User Content that
              violates these Terms or for which you do not have all the rights
              necessary to grant us the license described above. You represent
              and warrant that your User Content, and our use of such content as
              permitted by these Terms, will not violate any rights of or cause
              injury to any person or entity.
            </p>
            <p></p>
            <h2>6. Restrictions</h2>
            <p>
              Except as expressly authorized by these Terms, you may not (a)
              modify, disclose, alter, translate, or create derivative works of
              the Service (or any components thereof), (b) license, sublicense,
              resell, distribute, lease, rent, lend, transfer, assign, or
              otherwise dispose of the Service (or any components thereof), (c)
              use the Service to store or transmit any viruses, software
              routines, or other code designed to permit unauthorized access, to
              disable, erase, or otherwise harm software, hardware, or data, or
              to perform any other harmful actions, (d) copy, frame, or mirror
              any part or content of the Service, (e) build a competitive
              product or service, or copy any features or functions of the
              Service, (f) interfere with or disrupt the integrity or
              performance of the Service, (g) attempt to gain unauthorized
              access to the Service or their related systems or networks, (h)
              disclose to any third party any performance information or
              analysis relating to the Service, (i) circumvent or attempt to
              circumvent any technological protection measures intended to
              restrict access to or use of any portion of the Service or the
              functionality of the Service, (j) take any action that imposes an
              unreasonable or disproportionately large load on the Service, (k)
              use the Service for any purpose that is illegal in any way or that
              advocates illegal activity, or (l) cause or permit any
              unauthorized individual to do any of the foregoing.
            </p>
            <p></p>
            <h2>7. Trademarks</h2>
            <p>
              TrustLoKR's name, tagline, logo and the look and feel of the
              Service are our trademarks and may not be copied, imitated or
              used, in whole or in part, without our prior written permission.
              All other trademarks, registered trademarks, product names and
              company names or logos mentioned on the Service are the property
              of their respective owners. Reference to any products, services,
              processes or other information by trade name, trademark,
              manufacturer, supplier or otherwise does not constitute or imply
              endorsement, sponsorship or recommendation by us.
            </p>
            <p></p>
            <h2>8. Third-Party Services</h2>
            <p>
              We make no claim or representation regarding, and accept no
              responsibility for, the quality, content, nature, or reliability
              of third-party sites, products, or services accessible by link
              from the Service ("Third-Party Services"). We provide these links
              to you only as a convenience and the inclusion of any link does
              not imply our affiliation, endorsement, or adoption of the
              corresponding site or any information contained in (or made
              available via) any Third-Party Services. When you leave the
              Service, our terms and policies no longer govern. You should
              review the applicable terms and policies, including privacy and
              data-gathering practices, of any Third-Party Services to which you
              navigate from the Service.
            </p>
            <p></p>
            <h2>9. Indemnification</h2>
            <p>
              You agree, at your sole expense, to defend, indemnify and hold us
              (and our directors, officers, employees, consultants and agents)
              harmless from and against any and all actual or threatened suits,
              actions, proceedings (at law or in equity), claims, damages,
              payments, deficiencies, fines, judgments, settlements,
              liabilities, losses, costs and expenses (including, but not
              limited to, reasonable attorneys' fees, costs, penalties, interest
              and disbursements) that we (or our directors, officers, employees,
              consultants, or agents, individually or collectively) incur and
              are caused by, arise out of, result from, are attributable to, or
              are in any way incidental to any of your conduct or any actual or
              alleged breach of any of your obligations under these Terms.
            </p>
            <p></p>
            <h2>10. Disclaimers</h2>
            <p>
              THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
              WITHOUT ANY REPRESENTATIONS, WARRANTIES, COVENANTS, OR CONDITIONS
              OF ANY KIND (EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE),
              INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
              TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
              WITHOUT LIMITING THE FOREGOING, TRUSTLOKR, ITS AFFILIATES, AND ITS
              LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE ACCESS TO OR
              USE OF THE SERVICE WILL BE SECURE, TIMELY, UNINTERRUPTED,
              ERROR-FREE, OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE,
              SOFTWARE, SYSTEM, OR DATA, (B) ANY STORED USER CONTENT WILL NOT BE
              LOST, DAMAGED, OR CORRUPTED, (C) THE SERVICE OR THE SERVER(S) THAT
              MAKE THE SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS, OR (D) THIRD-PARTY DISRUPTIONS AND SECURITY BREACHES
              OF THE SERVICE WILL BE PREVENTED.
            </p>
            <p></p>
            <h2>11. Limitation of Liability</h2>
            <p>
              TRUSTLOKR'S ENTIRE LIABILITY TO YOU WILL NOT EXCEED THE
              SUBSCRIPTION FEES ACTUALLY PAID BY YOU TO TRUSTLOKR DURING THE
              SUBSCRIPTION PERIOD WITHIN WHICH THE DAMAGES OCCURRED. FURTHER,
              NOTWITHSTANDING ANY TERMS TO THE CONTRARY IN THIS AGREEMENT, (A)
              THE SOLE AND EXCLUSIVE REMEDY FOR ANY FAILURE OF THE TERMS OR
              CONDITIONS OF THE SERVICE LEVEL STANDARDS ARE THE CREDITS PROVIDED
              UNDER THIS AGREEMENT, AND (B) TRUSTLOKR WILL NOT BE LIABLE FOR ANY
              DISCLOSURE OF, UNAUTHORIZED USE OF AND/OR UNAUTHORIZED ACCESS TO
              ANY USER CONTENT OR OTHER INFORMATION EXCEPT IF SUCH OCCURS AS A
              RESULT OF TRUSTLOKR'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
              TRUSTLOKR WILL BE LIABLE FOR ANY LOSS OF PROFITS OR ANY INDIRECT,
              SPECIAL, INCIDENTAL, RELIANCE, OR CONSEQUENTIAL DAMAGES OF ANY
              KIND, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF
              INFORMED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE. MULTIPLE
              CLAIMS WILL NOT EXPAND THIS LIMITATION. THIS SECTION 11 WILL BE
              GIVEN FULL EFFECT EVEN IF ANY REMEDY SPECIFIED IN THIS AGREEMENT
              IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
            </p>
            <p></p>
            <h2>12. Dispute Resolution; Binding Arbitration</h2>
            <p>
              PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES
              YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US AND LIMITS
              THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US, UNLESS YOU OPT
              OUT OF ARBITRATION BY FOLLOWING THE INSTRUCTIONS SET FORTH BELOW.
              NO CLASS, COLLECTIVE OR REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE
              ALLOWED UNDER THIS ARBITRATION AGREEMENT, AND THE ARBITRATOR WILL
              HAVE NO JURISDICTION OR AUTHORITY TO COMPEL ANY CLASS OR
              COLLECTIVE CLAIM OR CONSOLIDATE DIFFERENT ARBITRATION PROCEEDINGS.
              IN ADDITION, ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR
              HAVING A JURY OR BENCH TRIAL IN CONNECTION WITH ANY CLAIM COVERED
              BY THIS ARBITRATION AGREEMENT.
            </p>
            <p></p>
            <p>
              No Representative Actions. The parties agree that any dispute
              arising out of or related to these Terms or the Service is
              personal to the parties and that any dispute will be resolved
              solely through individual action, and will not be brought as a
              class arbitration, class action or any other type of
              representative proceeding.
            </p>
            <p></p>
            <p>
              Arbitration of Disputes. Except for small claims disputes in which
              a party seeks to bring an individual action in small claims court
              located in the county of your billing address or disputes in which
              a party seeks injunctive or other equitable relief for the alleged
              unlawful use of intellectual property, each party waives its
              rights to a jury trial and to have any dispute arising out of or
              related to these Terms or the Service resolved in court. Expressly
              excepted from this arbitration agreement to arbitrate are
              employment-related claims such as those concerning employment
              termination, discrimination, harassment, and wage and hour issues
              under state and federal law. For any other type of dispute or
              claim that you have against us or relating in any way to the
              Service, you agree to first contact us and attempt to resolve the
              claim informally by sending a written notice of your claim
              ("Notice") to us by email at
              <a href='mailto:contact@trustlokr.com'> contact@trustlokr.com</a>
              or by certified mail addressed to TRUSTLOKR, 210 Sulling Way,
              Alpharetta, Georgia 30009. The Notice must (a) include your name,
              residence address, email address, and telephone number, (b)
              describe the nature and basis of the claim, and (c) set forth the
              specific relief sought. Our notice to you will be similar in form
              to that described above. If the parties cannot reach an agreement
              to resolve the claim within thirty (30) days after such Notice is
              received, then either party may submit the dispute to binding
              arbitration administered by JAMS or, under the limited
              circumstances set forth above, in court. All disputes submitted to
              JAMS will be resolved through confidential, binding arbitration
              before one arbitrator. Arbitration proceedings will be held in
              Atlanta, Georgia in accordance with the JAMS Streamlined
              Arbitration Rules and Procedures ("JAMS Rules"). The arbitrator
              will apply Federal Rules of Civil Procedure Rule 56 and Rule 68
              which can be found at
              https://www.law.cornell.edu/rules/frcp/rule_56 and
              https://www.law.cornell.edu/rules/frcp/rule_68. The most recent
              version of the JAMS Rules are available on the JAMS website and
              are hereby incorporated by reference. You either acknowledge and
              agree that you have read and understand the JAMS Rules or waive
              your opportunity to read the JAMS Rules and waive any claim that
              the JAMS Rules are unfair or should not apply for any reason.
            </p>
            <p></p>
            <p>
              The parties agree that these Terms affect interstate commerce and
              that the enforceability of this arbitration agreement will be
              substantively and procedurally governed by the Federal Arbitration
              Act, 9 U.S.C. &sect; 1, et seq. (the "FAA"), to the maximum extent
              permitted by applicable law. As limited by the FAA, these Terms
              and the JAMS Rules, the arbitrator will have exclusive authority
              to make all procedural and substantive decisions regarding any
              dispute and to grant any remedy that would otherwise be available
              in court, including the power to determine the question of
              arbitrability. The arbitrator may conduct only an individual
              arbitration and may not consolidate more than one individual's
              claims, preside over any type of class or representative
              proceeding or preside over any proceeding involving more than one
              individual.
            </p>
            <p></p>
            <p>
              The arbitrator and the parties will maintain the confidentiality
              of any arbitration proceedings, judgments and awards, including,
              but not limited to, all information gathered, prepared and
              presented for purposes of the arbitration or related to the
              dispute(s) therein. The arbitrator will have the authority to make
              appropriate rulings to safeguard confidentiality, unless the law
              provides to the contrary. The duty of confidentiality does not
              apply to the extent that disclosure is necessary to prepare for or
              conduct the arbitration hearing on the merits, in connection with
              a court application for a preliminary remedy or in connection with
              a judicial challenge to an arbitration award or its enforcement,
              or to the extent that disclosure is otherwise required by law or
              judicial decision.
            </p>
            <p></p>
            <p>
              The parties agree that for any arbitration you initiate, you will
              pay the filing fee and we will pay the remaining JAMS fees and
              costs. For any arbitration initiated by us, we will pay all JAMS
              fees and costs. The parties agree that the state or federal courts
              of the State of Georgia and the United States sitting in Atlanta,
              Georgia have exclusive jurisdiction over any appeals and the
              enforcement of an arbitration award.
            </p>
            <p></p>
            <p>
              This arbitration agreement does not bar or preclude any party from
              seeking immediate equitable injunctive relief on an emergency or
              ex parte basis, to the extent permitted by applicable law and
              rules of court, arising from the breach of these Terms, and based
              on a good faith belief that material irreparable harm will result
              unless equitable relief is sought and granted. All other claims,
              causes of action, remedies, damages, and forms of relief in law or
              equity will be subject to arbitration under this arbitration
              agreement to the extent permitted by applicable law. The parties
              further agree that no bond or other security will be required in
              obtaining such equitable relief and hereby waive any such
              requirement and consent to the issuance of such injunction and to
              the ordering of specific performance.
            </p>
            <p></p>
            <p>
              The arbitrator will have exclusive authority to resolve any
              dispute relating to the interpretation, applicability,
              enforceability or formation of this arbitration agreement,
              including, but not limited to, disputes regarding the
              arbitrability of claims and any claim that all or any part of this
              arbitration agreement is void or voidable.
            </p>
            <p></p>
            <p>
              If any portion of this arbitration agreement is found to be
              unenforceable or unlawful for any reason, (a) the unenforceable or
              unlawful provision will be severed from these Terms, (b) severance
              of the unenforceable or unlawful provision will have no impact
              whatsoever on the remainder of this arbitration agreement or the
              parties' ability to compel arbitration of any remaining claims on
              an individual basis pursuant to this arbitration agreement, and
              (c) to the extent that any claims must therefore proceed on a
              class, collective, consolidated, or representative basis, such
              claims will be litigated in a civil court of competent
              jurisdiction and not in arbitration, and the parties agree that
              litigation of those claims will be stayed pending the outcome of
              any individual claims in arbitration. Further, if any part of this
              arbitration agreement is found to prohibit an individual claim
              seeking public injunctive relief, that provision will have no
              effect to the extent such relief is allowed to be sought out of
              arbitration, and the remainder of this arbitration agreement will
              be enforceable.
            </p>
            <p></p>
            <p>
              You have the right to opt out of binding arbitration within 30
              days of the date you first accepted the terms of this arbitration
              agreement by sending an email to
              <a href='mailto:contact@trustlokr.com'>contact@trustlokr.com</a>.
              In order to be effective, the opt-out notice must include your
              full name and address and clearly indicate your intent to opt out
              of binding arbitration. By opting out of binding arbitration, you
              are agreeing to resolve disputes in accordance with Section 12 of
              these Terms.
            </p>
            <p></p>
            <h2>13. General</h2>
            <p>
              These Terms will be governed by and construed in accordance with
              the laws of the State of Georgia applicable to agreements made and
              to be entirely performed within the State of Georgia, without
              resorting to its conflict of law provisions. TrustLoKR may, by
              itself or through an independent third party, audit your use of
              the Service to verify your compliance with these Terms.
              Notwithstanding any terms to the contrary in these Terms, any
              suggestions, comments, or other feedback provided by you with
              respect to TrustLoKR or the Service (collectively, "Feedback")
              will constitute confidential information of TrustLoKR. Further,
              TrustLoKR will be free to use, disclose, reproduce, license, and
              otherwise distribute and exploit the Feedback provided to it as it
              sees fit, entirely without obligation or restriction of any kind
              on account of intellectual property rights or otherwise. You
              hereby assign to TrustLoKR all right, title and interest you may
              have in and to the Feedback (including all intellectual property
              rights embodied in the Feedback). You acknowledge and agree that
              TrustLoKR may monitor, collect, use, and store anonymous and
              aggregate statistics regarding use of the Service and/or any
              individuals/entities that interact with the Service (collectively,
              "TrustLoKR Analytic Data"). Notwithstanding any terms to the
              contrary in these Terms, as between the parties and subject to the
              grants expressly set forth in these Terms, TrustLoKR owns all
              right, title, and interest in and to the Service, and the
              TrustLoKR Analytic Data, together with any and all intellectual
              property rights embodied in or related to the foregoing. You agree
              that regardless of any statute or law to the contrary, any claim
              or cause of action arising out of or related to use of the Service
              or these Terms must be filed within one year after such claim or
              cause of action arose or be forever barred. Neither these Terms
              nor any right or duty under these Terms may be transferred,
              assigned or delegated by you, by operation of law or otherwise,
              without the prior written consent of TrustLoKR, and any attempted
              transfer, assignment or delegation without such consent will be
              void and without effect. TrustLoKR may freely transfer, assign or
              delegate these Terms or its rights and duties under these Terms.
              Subject to the foregoing, these Terms will be binding upon and
              will inure to the benefit of the parties and their respective
              representatives, heirs, administrators, successors and permitted
              assigns. No modification, addition or deletion, or waiver of any
              rights under these Terms will be binding on a party unless clearly
              understood by the parties to be a modification or waiver and
              signed by a duly authorized representative of each party. No
              failure or delay (in whole or in part) on the part of a party to
              exercise any right or remedy hereunder will operate as a waiver
              thereof or effect any other right or remedy. All rights and
              remedies hereunder are cumulative and are not exclusive of any
              other rights or remedies provided hereunder or by law. The waiver
              of one breach or default or any delay in exercising any rights
              will not constitute a waiver of any subsequent breach or default.
              If any provision of these Terms is invalid, illegal, or incapable
              of being enforced by any rule of law or public policy, all other
              provisions of these Terms will nonetheless remain in full force
              and effect so long as the economic and legal substance of the
              transactions contemplated by these Terms are not affected in any
              manner adverse to any party. Upon such determination that any
              provision is invalid, illegal, or incapable of being enforced, the
              parties will negotiate in good faith to modify these Terms so as
              to effect the original intent of the parties as closely as
              possible in an acceptable manner to the end that the transactions
              contemplated hereby are fulfilled. Except for payments due under
              these Terms, neither party will be liable for any delays or
              failures in performance due to circumstances beyond its reasonable
              control, including, but not limited to, acts of God, flood, fire,
              earthquake, or explosion, war, invasion, hostilities (whether war
              is declared or not), terrorist threats or acts, sabotage or
              piracy, riot or other civil unrest, government order, law, or
              action, embargoes or blockades in effect on or after the date of
              this Agreement, strikes, labor stoppages or slowdowns or other
              industrial disturbances, interruption or termination of any
              services provided by any service providers used by TrustLoKR, and
              plague, epidemic, pandemic, outbreaks of infectious disease or any
              other public health crisis (including, quarantine or other
              employee restrictions) (each a "Force Majeure Event").
            </p>
            <p></p>
            <p>
              If you are a California resident, you may have these Terms mailed
              to you electronically by sending a letter to TrustLoKR, 210
              Sulling Way, Alpharetta, Georgia 30009 with your electronic mail
              address and a request for these Terms. Under California Civil Code
              Section 1789.3, California Website users are entitled to the
              following specific consumer rights notice: The Complaint
              Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs may be contacted in
              writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
              California 95834, or by telephone at (800) 952-5210.
            </p>
            <p></p>
            <h2>14. Additional Terms Applicable to Mobile Devices</h2>
            <p>
              The following terms apply if you install, access or use the
              Service on any device that contains the iOS mobile operating
              system (the "iOS App") developed by Apple Inc. ("Apple").
            </p>
            <p></p>
            <p>
              Acknowledgment. You acknowledge that these Terms are concluded
              solely between you and us, and not with Apple, and we, not Apple,
              are solely responsible for the iOS App and the content thereof.
              You further acknowledge that the usage rules for the iOS App are
              subject to any additional restrictions set forth in the Usage
              Rules for the TrustLoKRiOS App Store Terms of Service (the "Usage
              Rules") as of the date you download the iOS App, and in the event
              of any conflict, the Usage Rules will govern if they are more
              restrictive. You acknowledge and agree that you have had the
              opportunity to review the Usage Rules.
            </p>
            <p></p>
            <p>
              Scope of License. The license granted to you is limited to a
              non-transferable license to use the iOS App on any iPhone, iPod
              touch or iPad that you own or control as permitted by the Usage
              Rules.
            </p>
            <p></p>
            <p>
              Maintenance and Support. The parties acknowledge that TrustLoKR
              has no obligation whatsoever to furnish any maintenance and
              support services with respect to the iOS App.
            </p>
            <p></p>
            <p>
              Warranty. You acknowledge that TrustLoKR is not responsible for
              any product warranties, whether express or implied by law, with
              respect to the iOS App. In the event of any failure of the iOS App
              to conform to any applicable warranty, you may notify TrustLoKR,
              and TrustLoKR will refund the purchase price, if any, paid to
              Apple for the iOS App by you; and to the maximum extent permitted
              by applicable law, Apple will have no other warranty obligation
              whatsoever with respect to the iOS App. The parties acknowledge
              that to the extent that there are any applicable warranties, any
              other claims, losses, liabilities, damages, costs, or expenses
              attributable to any failure to conform to any such applicable
              warranty would be our sole responsibility. However, you understand
              and agree that in accordance with these Terms, we have disclaimed
              all warranties of any kind with respect to the iOS App, and
              therefore, there are no warranties applicable to the iOS App.
            </p>
            <p></p>
            <p>
              Product Claims. The parties acknowledge that as between Apple and
              us, we, not Apple, are responsible for addressing any claims
              relating to the iOS App or your possession and/or use of the iOS
              App, including, but not limited to (a) product liability claims,
              (b) any claim that the iOS App fails to conform to any applicable
              legal or regulatory requirement, and (c) claims arising under
              consumer protection or similar legislation.
            </p>
            <p></p>
            <p>
              Intellectual Property Rights. The parties acknowledge that, in the
              event of any third-party claim that the iOS App or your possession
              and use of the iOS App infringe that third party's intellectual
              property rights, we, and not Apple, will be solely responsible for
              the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim to the extent required
              under these Terms.
            </p>
            <p>
              Legal Compliance. You represent and warrant that (a) you are not
              located in a country that is subject to a U.S. Government embargo,
              or that has been designated by the U.S. Government as a "terrorist
              supporting" country, and (b) you are not listed on any U.S.
              Government list of prohibited or restricted parties.
            </p>
            <p>
              Developer Name and Address. Any questions, complaints or claims
              with respect to the iOS App should be directed to:
            </p>
            <p>
              TrustLoKR, 210 Sulling Way, Alpharetta, Georgia 30009, email:
              <a href='mailto:contact@trustlokr.com'>contact@trustlokr.com</a>.
            </p>
            <p>
              Third-Party Terms of Agreement. You agree to comply with any
              applicable third-party terms when using the Service.
            </p>
            <p>
              Third-Party Beneficiary. The parties acknowledge and agree that
              Apple, and Apple's subsidiaries, are third-party beneficiaries of
              these Terms, and that, upon your acceptance of these Terms, Apple
              will have the right (and will be deemed to have accepted the
              right) to enforce these Terms against you as a third-party
              beneficiary thereof.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default terms
